<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Buddies" subtitle="Keep in touch with your friends and see what they're playing." :tabs="tabs" />

	<app-content-body :loading="is.loading && is.initialised" :is-call="!isConnected || !collection.length" :is-grid="collection.length">

		<app-content-call v-if="!isConnected" icon="buddy.suggest" title="Do you have GeekBuddies or log opponents on BGG?" text="Connect your BGG username to see who is known to be attending the convention." button="Connect" v-on:click="onConnectClick" />
		<app-content-call v-if="!collection.length && isConnected" icon="buddy.suggest" title="No suggestions currently" text="Suggestions are generated from your GeekBuddies and logged play opponents on BGG. For a user to be suggested they must also be registered for the convention and have connected their BGG username." />
		<app-buddy v-for="item in collection" :key="item.key" :buddy="item" />

	</app-content-body>

	<com-connect v-if="is.connecting" v-on:close="is.connecting = false" v-on:connected="load(true)" />

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'
import comConnect from './common/panel/Connect'

export default {

	mixins: [mixCollection],

	components: {
		'com-connect': comConnect
	},

	data: function() {

		return {
			is: {
				connecting: false
			},
			pagination: false,
			endpoint: 'convention/buddies/suggested'
		}

	},

	computed: {

		isConnected: function() {

			return this.$store.getters['session/bgg']

		},

		tabs: function() {

			return [
				{ name: 'Convention.Buddies', text: 'Buddies' },
				{ name: 'Convention.Buddies.Suggested', text: 'Suggested' },
				{ name: 'Convention.Buddies.Directory', text: 'Directory' }
			]

		}

	},

	methods: {

		onConnectClick: function() {

			this.is.connecting = true

		}

	}

}

</script>

<style scoped>

</style>