<template>

<app-panel v-on:close="$emit('close')" :theme="(is.connected) ? 'green' : 'default'">
		
	<app-panel-title text="Connect to BGG" v-if="!is.connected" />

	<div class="connect" v-if="is.connected">

		<div class="connect-avatar" v-bgimage="$store.getters['session/avatar']" />
		<div class="connect-username">{{ $store.getters['session/bgg'].username }}</div>

	</div>

	<app-panel-text align="center" v-if="is.connected" text="You have successfully connected your BGG username." />

	<template v-if="!is.connected">

		<app-panel-text text="Connect your BGG username so you can quickly find your GeekBuddies and logged opponents who are also attending the same conventions. It will also pull in your avatar." />

		<app-panel-text text="As this feature uses publically available data so there is no need to enter your password or verify your account." />

		<app-panel-text text="Enter your BGG username below and click Confirm to start connecting with your buddies." />

	</template>

	<app-panel-content v-if="!is.connected">
		
		<app-input-text placeholder="Enter BGG username" :disabled="is.loading" :stacked="true" v-model="model.username" :validation="$v.model.username" />

	</app-panel-content>

	<app-panel-content>

		<app-button v-if="!is.connected" text="Confirm" :loading="is.loading" :status="status" :disabled="$v.invalid" v-on:click="onConfirmClick" />
		<app-button v-if="is.connected" text="Close" theme="green" v-on:click="$emit('close')" />

	</app-panel-content>

</app-panel>

</template>

<script>

import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [validationMixin],

	props: ['data'],

	data: function() {

		return {
			status: false,
			is: {
				loading: false,
				connected: false
			},
			model: {
				username: ''
			}
		}

	},

	validations: {
		model: {
			username: {
				required
			}
		}
	},

	created: function() {

		this.$pubsub.$emit('offset', true)

	},

	beforeDestroy: function() {

		this.$pubsub.$emit('offset', false)

	},

	methods: {

		onConfirmClick: function() {

			this.is.loading = true
			this.status = this.$constants.status.loading

			this.$api.post('session/bgg', this.model).then(function() {

				this.is.loading = false
				this.is.connected = true
				
				this.$emit('connected')

			}.bind(this), function() {

				this.is.loading = false
				this.status = this.$constants.status.failed

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.connect {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px 0px;
}

.connect-avatar {
	width: 128px;
	height: 128px;
	border-radius: 50%;
	background-size: cover;
	background-position: 50% 50%;
	margin-bottom: 10px;
}

.connect-username {
	font-size: 20px;
	letter-spacing: 1px;
}

</style>
